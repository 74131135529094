import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import interaction11 from '../img/interaction11.png';
import interaction12 from '../img/interaction12.png';
import interaction13 from '../img/interaction13.png';

const Guide = props => {
  const history = useHistory();

  return (
    <div>
      <Row>
        <Col lg="6">
          <div style={{marginTop: "1em"}}>
            <p style={{fontWeight: "bold", fontSize: "11.5pt"}}>1. カメラで撮影し、AIで健康状態のリスク予測を開始します。<br />　「開始」ボタンをタップしてください。</p>
            <Image src={interaction11} fluid={true} style={{ width: "68%", height: "auto" }} />
          </div>
          <div style={{marginTop: "1em"}}>
            <p style={{fontWeight: "bold", fontSize: "11.5pt"}}>2. 健康状態のリスクが表示されたら「撮影」ボタンをタップしてください。<br />　「NO DENTAL」が表示された場合は、近づいて、歯と歯ぐきをハッキリ<br />　映してください。</p>
            <Image src={interaction12} fluid={true} style={{width: "68%", height: "auto"}} />
          </div>
        </Col>
        <Col lg="6">
          <div style={{marginTop: "1em"}}>
            <p style={{fontWeight: "bold", fontSize: "11.5pt"}}>3. 健康状態のリスクを確認して、「決定」ボタンをタップしてください。<br />　撮り直しをしたい場合は、再度「撮影」ボタンをタップしてください。</p>
            <Image src={interaction13} fluid={true} style={{width: "68%", height: "auto"}} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div className="d-grid gap-2" style={{margin: "1.5em auto"}}>
            <Button size="lg" onClick={() => {history.goBack()}}>戻る</Button>
          </div>
        </Col>
        <Col lg="6">
          <div className="d-grid gap-2" style={{margin: "1.5em auto 3em"}}>
            <Button id="camera-button" size="lg" onClick={() => {history.push('/recognizeCamera')}}>口腔画像を撮影する</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Guide;