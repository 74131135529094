import React, { createContext, useState, useEffect, useContext } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Redirect, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { fetch } from 'whatwg-fetch';
import DentalStatusNavbar from './components/Navbar';
import Start from './components/Start';
import Terms from './components/Terms';
import About from './components/About';
import Recognize from './components/Recognize';
import Camera from './components/Camera';
import RecognizeCameraGuide from './components/RecognizeCameraGuide';
import RecognizeCamera from './components/RecognizeCamera';
import Result from './components/Result';
import Advice from './components/Advice';

export const RecognizeContext = createContext();

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
 
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return windowDimensions;
}

const UseOrgCode = () => {
  const { orgCode, setOrgCode, setMode } = useContext(RecognizeContext);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  if (token !== null && token !== "" && orgCode === "") {
      fetch(process.env.REACT_APP_API_ROOT + "/token", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          token: token
        })
      }).then(response => response.json()).then((responseJson) => {
        console.log(responseJson);
        if (responseJson.token_valid) {
          setOrgCode(responseJson.org_code);
          setMode(responseJson.mode);
        }
      }, (error) => {
        console.log(error);
      });
  }
  return "";
}

const PrivateRoute = ({ ...props }) => {
  const { orgCode, agreed } = useContext(RecognizeContext);
  if (orgCode !== "" && agreed) {
    return <Route {...props} />
  } else {
    return <Redirect to="/" />
  }
}

function App() {
  const [agreed, setAgreed] = useState(false);
  const [orgCode, setOrgCode] = useState("");
  const [mode, setMode] = useState("CAMERA");
  const [userCode, setUserCode] = useState("");
  const [image, setImage] = useState(undefined);
  const [recognizeResult, setRecognizeResult] = useState(null);
  const [target01, setTarget01] = useState(false);
  const [target02, setTarget02] = useState(false);
  const [target03, setTarget03] = useState(false);
  const [target04, setTarget04] = useState(false);
  const [target05, setTarget05] = useState(false);
  const [target06, setTarget06] = useState(false);
  const [target07, setTarget07] = useState(false);
  const [target08, setTarget08] = useState(false);
  const [target09, setTarget09] = useState(false);
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [childAge, setChildAge] = useState("");
  const value = {
    agreed, setAgreed,
    orgCode, setOrgCode,
    mode, setMode,
    userCode, setUserCode,
    image, setImage,
    recognizeResult, setRecognizeResult,
    target01, setTarget01,
    target02, setTarget02,
    target03, setTarget03,
    target04, setTarget04,
    target05, setTarget05,
    target06, setTarget06,
    target07, setTarget07,
    target08, setTarget08,
    target09, setTarget09,
    gender, setGender,
    age, setAge,
    childAge, setChildAge,
  };

  return (
    <Router>
      <RecognizeContext.Provider value={value}>
        <UseOrgCode />
        <DentalStatusNavbar />
        <MainContent />
      </RecognizeContext.Provider>
    </Router>
  );
}

function MainContent() {
  const location = useLocation();
  let className = '';
  switch (location.pathname) {
    case '/recognizeCamera':
      className = 'black';
      break;
    case '/advice':
      className = 'advice';
      break
    default:
      break;
  }

  return (
    <div id="main" className={className}>
      <div id="content">
        <Container>
          <Route exact path="/" component={Start} />
          <Route exact path="/terms" component={Terms} />
          <PrivateRoute exact path="/recognize" component={Recognize} />
          <PrivateRoute exact path="/camera" component={Camera} />
          <PrivateRoute exact path="/recognizeCameraGuide" component={RecognizeCameraGuide} />
          <PrivateRoute exact path="/recognizeCamera" component={RecognizeCamera} />
          <PrivateRoute exact path="/result" component={Result} />
          <PrivateRoute exact path="/advice" component={Advice} />
          <Route exact path="/about" component={About} />
        </Container>
      </div>
    </div>
  );
}

export default App;
