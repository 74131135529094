import React, { useContext, useEffect } from 'react';
import { RecognizeContext } from '../App';
import logo_320 from '../img/logo_320.png';
import { Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

const Start = props => {
  const { 
    setAgreed,
    orgCode,
    mode,
    userCode,
    setUserCode,
    setImage,
    setRecognizeResult,
    setTarget01,
    setTarget02,
    setTarget03,
    setTarget04,
    setTarget05,
    setTarget06,
    setTarget07,
    setTarget08,
    setTarget09,
    setGender,
    setAge,
    setChildAge
  } = useContext(RecognizeContext);
  const history = useHistory();

  useEffect(() => {
    console.log("start...");
    setUserCode("");
    setImage(undefined);
    setRecognizeResult(null);
    setTarget01(false);
    setTarget02(false);
    setTarget03(false);
    setTarget04(false);
    setTarget05(false);
    setTarget06(false);
    setTarget07(false);
    setTarget08(false);
    setTarget09(false);
    setGender("");
    setAge("");
    setChildAge("");
  }, [setUserCode, setImage, setRecognizeResult, setTarget01, setTarget02, setTarget03, setTarget04, setTarget05, setTarget06, setTarget07, setTarget08, setTarget09, setAge, setChildAge, setGender]);

  const clickStart = () => {
    if (userCode === "") {
      alert("個人番号を入力してください");
      return;
    }
    setAgreed(true);
    if (mode === 'RECOGNIZE_CAMERA') {
      history.push("/recognizeCameraGuide");
    } else {
      history.push("/recognize");
    }
  }

  return (
    (orgCode === "")
      ?
      <div>
        <h1>Not Authorized!</h1>
      </div>
      :
      <div>
        <div style={{ textAlign: "center" }}>
          <div>
            <h1 style={{ fontSize: "32px" }}>DENTAL STATUS<br /><small style={{ fontSsize: "14pt" }}>口腔環境のチェック</small></h1>
          </div>
          <div>
            <img src={logo_320} alt="" style={{ width: "240px", height: "auto" }} />
          </div>
          <div>
            <Row>
              <Col xs="7">
                <Link to="/about">DENTAL STATUSとは</Link>
              </Col>
              <Col xs="5">
                <Link to="/terms">利用規約</Link>
              </Col>
            </Row>
            <div style={{ margin: "2em auto" }}>
              <InputGroup className="mb-3">
                <FormControl id="user-code" placeholder="個人番号" aria-label="個人番号" value={userCode} onChange={(event) => setUserCode(event.target.value)} />
              </InputGroup>
            </div>
            <div className="d-grid gap-2">
              <Button onClick={clickStart} size="lg">利用規約に同意して開始する</Button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Start