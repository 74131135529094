import React, { useContext, useState } from 'react';
import { RecognizeContext } from '../App';
import { Row, Col, Button, Image, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import NumPad from 'react-numpad';
import resultImageHeavy from '../img/result_heavy.png';
import resultImageMedium from '../img/result_medium.png';
import resultImageLow from '../img/result_low.png';

const Result = props => {
  const {
    mode,
    image,
    recognizeResult,
    target01, setTarget01, 
    target02, setTarget02,
    target03, setTarget03,
    target04, setTarget04,
    target05, setTarget05,
    target06, setTarget06,
    target07, setTarget07,
    target08, setTarget08,
    target09, setTarget09,
    gender, setGender,
    age, setAge,
    childAge, setChildAge } = useContext(RecognizeContext)
  const [isChild, setIsChild] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)

  const history = useHistory();

  let rankImage = undefined;
  if (recognizeResult.top_class === "low") {
    rankImage = resultImageLow;
  } else if (recognizeResult.top_class === "medium") {
    rankImage = resultImageMedium;
  } else if (recognizeResult.top_class === "heavy") {
    rankImage = resultImageHeavy;
  }

  const startAdvice = () => {
    history.push('/advice');
  }

  const changeGender = (event) => {
    setGender(event.target.value);
  }

  const changeAge = (event) => {
    setAge(event.target.value);
    if (event.target.value === '0-12') {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
  }

  const getDisplayGender = () => {
    switch (gender) {
      case 'male':
        return '男性'
      case 'female':
        return '女性'
      case 'none':
        return '無回答'
      default:
        return '性別'
    }
  }

  const getDispalyAge = () => {
    switch (age) {
      case '0-12':
        return `${childAge}歳`;
      case '13':
        return '13-20歳'
      case '21':
        return '21-30歳'
      case '31':
        return '31-40歳'
      case '41':
        return '41-50歳'
      case '51':
        return '51-60歳'
      case '61':
        return '61-70歳'
      case '71':
        return '71歳以上'
      case 'none':
        return '無回答'
      default:
        return '年齢'
    }
  }

  const clickAgeGender = () => {
    openModal();
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const afterOpenModal = () => {    
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const modalStyles = {
    content: {
      top: "25%",
      left: "25%",
      right: "auto",
      bottom: "auto",
      width: "50%",
    },
  };

  Modal.setAppElement("#root");

  return (
    <div>
      <Row>
        <Col lg="6">
          <div style={{textAlign: "center"}}>
            <Image src={image} fluid={true} style={{margin: "1em auto", maxHeight: "300px"}} />
          </div>
        </Col>
        <Col lg="6">
          <div style={{textAlign: "center"}}>
            <Image src={rankImage} fluid={true} style={{maxHeight: "300px"}} />
            <div style={{fontSize: "10pt", textAlign: "center"}}>確定的な診断結果をお伝えするものではありません</div>
            <div className="d-grid gap-2" style={{margin: "1em auto"}}>
              <Button size="lg" onClick={() => {history.go(mode === 'RECOGNIZE_CAMERA' ? -3 : -2)}}>結果表示を終わる</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: "1em auto" }}>
        <Col lg="12">
        <div>AIに聞いてみたい項目を教えていただけますか？（複数選択可）選択後、「アドバイス開始」ボタンを押してください。<br />性別と年齢をご入力いただくと、より具体的なアドバイスをご提供しやすくなります。</div>
          <Row style={{marginTop: "0.5em"}}>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${age || gender ? 'btn-success' : 'btn-secondary'}`} onClick={() => clickAgeGender()}>{gender ? `${getDisplayGender()}` : '性別'}・{age ? `${getDispalyAge()}` : '年齢'}</button>
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target01 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget01(!target01)}>歯みがき粉選び</button>
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target02 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget02(!target02)}>むし歯、歯が痛い</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target03 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget03(!target03)}>食事、栄養素</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target04 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget04(!target04)}>知覚過敏、しみる</button>          
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: "0.5em"}}>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target05 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget05(!target05)}>歯ブラシ選び</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target06 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget06(!target06)}>歯周病、血が出る</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target07 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget07(!target07)}>歯の色、黄ばみ</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target08 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget08(!target08)}>口臭の原因、対策</button>          
              </div>
            </Col>
            <Col>
              <div className="d-grid gap-2">
                <button className={`btn ${target09 ? 'btn-success' : 'btn-secondary'}`} onClick={() => setTarget09(!target09)}>補助具・フロス等</button>          
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
            </Col>
            <Col lg="6">
              <div className="d-grid gap-2" style={{margin: "1em auto"}}>
                <Button size="lg" onClick={() => startAdvice()}>アドバイス開始</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick={false} style={modalStyles}>
        <Row>
          <Col lg="4">
            <div>
              <div style={{fontWeight: 'bold'}}>性別</div>
              <Form.Select id="gender" size="lg" onChange={changeGender} value={gender}>
                <option value={'none'}>無回答</option>
                <option value={'male'}>男性</option>
                <option value={'female'}>女性</option>
              </Form.Select>
            </div>
          </Col>
          <Col lg="8">
            <div>
              <div style={{fontWeight: 'bold'}}>年代</div>
              <Form.Select size="lg" onChange={changeAge} value={age}>
                <option value={'none'}>無回答</option>
                <option value={'0-12'}>12歳以下</option>
                <option value={'13'}>13歳～20歳</option>
                <option value={'21'}>21歳～30歳</option>
                <option value={'31'}>31歳～40歳</option>
                <option value={'41'}>41歳～50歳</option>
                <option value={'51'}>51歳～60歳</option>
                <option value={'61'}>61歳～70歳</option>
                <option value={'71'}>71歳以上</option>
              </Form.Select>
              { isChild ?
                <>
                  <div style={{ margin: "0.5em auto" }}>年齢を入力してください。</div>
                  <NumPad.Number
                    onChange={(value) => {
                      setChildAge(value);
                    }}
                    label={'年齢'}
                    placeholder={''}
                    value={childAge}
                    decimal={2}
                  />
                </>
              : <></> 
              }
            </div>
          </Col>
        </Row>
        <div className="d-grid gap-2" style={{margin: "1em auto 0"}}>
          <Button size="lg" onClick={() => {
            console.log('childAge', parseInt(childAge));
            if (isChild && (parseInt(childAge) < 0 || parseInt(childAge) > 12)) {
              alert("0歳から12歳の場合は年齢を入力してください。");
            } else {
              closeModal()
            }
          }}>閉じる</Button>
        </div>
      </Modal>
    </div>
  );
}

export default Result