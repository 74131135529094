import React, { useContext, useEffect, useState, useRef } from 'react';
import { RecognizeContext } from '../App';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import resultImageHeavy from '../img/result_heavy.png';
import resultImageMedium from '../img/result_medium.png';
import resultImageLow from '../img/result_low.png';
import { BounceLoader } from 'react-spinners';

const override = {
  display: 'block',
  marin: '0 auto',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}

const Advice = props => {
  const {
    mode,
    image,
    recognizeResult,
    target01,
    target02,
    target03,
    target04,
    target05,
    target06,
    target07,
    target08,
    target09,
    gender,
    age,
    childAge } = useContext(RecognizeContext);
  const [aiAdvice, setAiAdvice] = useState('');
  const [aiImageAdvice, setAiImageAdvice] = useState('');
  const [healthScore, setHealthScore] = useState('');
  const [adviceParams, setAdviceParams] = useState({});

  let adviceController = useRef();
  let adviceSignal = useRef();
  let imageAdviceController = useRef();
  let imageAdviceSignal = useRef();

  const history = useHistory();

  const getDisplayGender = () => {
    switch (gender) {
      case 'male':
        return '男性'
      case 'female':
        return '女性'
      case 'none':
        return '無回答'
      default:
        return '性別'
    }
  }

  const getDispalyAge = () => {
    switch (age) {
      case '0-12':
        return `${childAge}歳`;
      case '13':
        return '13-20歳'
      case '21':
        return '21-30歳'
      case '31':
        return '31-40歳'
      case '41':
        return '41-50歳'
      case '51':
        return '51-60歳'
      case '61':
        return '61-70歳'
      case '71':
        return '71歳以上'
      case 'none':
        return '無回答'
      default:
        return '年齢'
    }
  }

  const editAiAdvice = (event) => {
    console.log(event.target.value);
  }

  const editAiImageAdvice = (event) => {
    console.log(event.target.value);
  }

  useEffect(() => {
    const targets = [];
    if (target01) targets.push("歯みがき粉選び");
    if (target02) targets.push("むし歯、歯が痛い");
    if (target03) targets.push("食事、栄養素");
    if (target04) targets.push("知覚過敏、しみる");
    if (target05) targets.push("歯ブラシ選び");
    if (target06) targets.push("歯周病、血が出る");
    if (target07) targets.push("歯の色、黄ばみ");
    if (target08) targets.push("口臭の原因、対策");
    if (target09) targets.push("補助具・フロス等");
    const params = {
      "gender": gender,
      "age": age,
      "child_age": childAge,
      "status": recognizeResult.top_class,
      "health_score": recognizeResult.health_score,
      "targets": targets,
      "check_id": recognizeResult.check_id,
      "char_count": 700,
    }
    setHealthScore(recognizeResult.health_score);
    setAdviceParams(params)
    adviceController.current = new AbortController();
    adviceSignal.current = adviceController.signal;
    fetch(process.env.REACT_APP_API_ROOT + "/generate_advice", {
      signal: adviceSignal.current,
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(params)
    }).then(response => response.json()).then((responseJson) => {
      console.log(responseJson);
      adviceController.current = null;
      adviceSignal.current = null;
      if (responseJson.result === "OK") {
        setAiAdvice(responseJson.generated_str);
      } else {
        console.error(responseJson);
      }
    }, (error) => {
      console.error(error);
      adviceController.current = null;
      adviceSignal.current = null;
    });
    imageAdviceController.current = new AbortController();
    imageAdviceSignal.current = imageAdviceController.signal;
    fetch(process.env.REACT_APP_API_ROOT + '/generate_image_advice', {
      signal: imageAdviceSignal.current,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        image: image.replace(/^.*,/, ''),
        health_score: recognizeResult.health_score,
        char_count: 200,
      })
    }).then(response => response.json()).then((responseJson) => {
      console.log(responseJson);
      imageAdviceController.current = null;
      imageAdviceSignal.current = null;
      if (responseJson.result === "OK") {
        setAiImageAdvice(responseJson.generated_str);
      } else {
        console.error(responseJson);
      }
    }, (error) => {
      console.log(error);
      imageAdviceController.current = null;
      imageAdviceSignal.current = null;
    });
  }, [image, gender, age, childAge, target01, target02, target03, target04, target05, target06, target07, target08, target09, recognizeResult])

  let rankImage = undefined;
  if (recognizeResult.top_class === "low") {
    rankImage = resultImageLow;
  } else if (recognizeResult.top_class === "medium") {
    rankImage = resultImageMedium;
  } else if (recognizeResult.top_class === "heavy") {
    rankImage = resultImageHeavy;
  }

  const print = () => {
    fetch(process.env.REACT_APP_API_ROOT + '/save_report', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        recognize_result: recognizeResult,
        generate_advice_params: adviceParams,
        targets: [target01, target02, target03, target04, target05, target06, target07, target08, target09],
        ai_advice: aiAdvice,
        ai_image_advice: aiImageAdvice
      })
    }).then(response => response.json()).then((responseJson) => {
      console.log(responseJson);
      if (responseJson.result === "OK") {
        console.log("saved!");
      } else {
        console.error(responseJson);
      }
      window.print();
    }, (error) => {
      console.log(error);
      window.print();
    });
  }

  const backTo = (i) => {
    if (adviceController.current != null) {
      adviceController.current.abort();
    }
    if (imageAdviceController.current != null) {
      imageAdviceController.current.abort();
    }
    history.go(i);
  }

  return (
    <div>
      <table id="advice-table" className='table table-bordered' width={"100%"}>
        <thead>
        <tr>
            <td colSpan={3} width={"100%"}>
              <Row style={{marginTop: "0.5em"}}>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${age || gender ? 'btn-success' : 'btn-secondary'}`}>{gender ? `${getDisplayGender()}` : '性別'}・{age ? `${getDispalyAge()}` : '年齢'}</button>
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target01 ? 'btn-success' : 'btn-secondary'}`}>歯みがき粉選び</button>
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target02 ? 'btn-success' : 'btn-secondary'}`}>むし歯、歯が痛い</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target03 ? 'btn-success' : 'btn-secondary'}`}>食事、栄養素</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target04 ? 'btn-success' : 'btn-secondary'}`}>知覚過敏、しみる</button>          
                  </div>
                </Col>
              </Row>
              <Row style={{marginTop: "0.5em"}}>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target05 ? 'btn-success' : 'btn-secondary'}`}>歯ブラシ選び</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target06 ? 'btn-success' : 'btn-secondary'}`}>歯周病、血が出る</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target07 ? 'btn-success' : 'btn-secondary'}`}>歯の色、黄ばみ</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target08 ? 'btn-success' : 'btn-secondary'}`}>口臭の原因、対策</button>          
                  </div>
                </Col>
                <Col>
                  <div className="d-grid gap-2">
                    <button className={`btn ${target09 ? 'btn-success' : 'btn-secondary'}`}>補助具・フロス等</button>          
                  </div>
                </Col>
              </Row>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Image src={image} fluid={true} style={{margin: "1em auto", maxHeight: "150px"}} /></td>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
              <div style={{fontSize: '14pt', fontWeight: 'bold'}}>歯と歯ぐきの健康状態</div>
              <div><Image src={rankImage} fluid={true} style={{maxHeight: "180px"}} /></div>
              <div style={{fontSize: '9pt'}}>確定的な診断結果をお伝えするものではありません</div>
            </td>
            <td style={{verticalAlign: 'middle'}}>
              <div style={{textAlign: 'right'}}><span style={{fontSize: '48pt', fontWeight: 'bold', color: 'red'}}>{healthScore}</span>／100点</div>
              <div style={{marginTop: '1em', textAlign: 'center', fontWeight: 'bold', color: 'red'}}>標準的な評価は60点です。</div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
            { aiAdvice !== '' && aiImageAdvice !== '' ?
                <textarea className="aiImageAdviceTextArea" value={aiImageAdvice} onChange={editAiImageAdvice} rows={5} />
              : <textarea className="aiImageAdviceTextArea" value={'アドバイスの生成中です。'} onChange={editAiImageAdvice} rows={5} />
            }
              <div style={{fontSize: "9pt"}}>本アドバイスは、歯の健康状態に関する一般的な知識に基づいています。</div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
            { aiAdvice !== '' && aiImageAdvice !== '' ?
                <textarea className="aiAdviceTextArea" value={aiAdvice} onChange={editAiAdvice} rows={20}/>
              : <textarea className="aiAdviceTextArea" value={'アドバイスの生成中です。'} onChange={editAiAdvice} rows={20} />
            }
            </td>
          </tr>
          <tr>
            <td colSpan={3} style={{fontSize: "9pt"}}>本紙記載内容は治療、診断を目的とするものではありません。詳細は最寄りの歯科医院など専門機関にご相談ください。</td>
          </tr>
        </tbody>
      </table>
      <div id="printBtn">
        <Button size="lg" onClick={() => print()}>　印　刷　</Button>　
        <Button size="lg" onClick={() => backTo(-1)}>　戻　る　</Button>　
        <Button size="lg" onClick={() => backTo(mode === 'RECOGNIZE_CAMERA' ? -4 : -3)}>結果表示を終わる</Button>
      </div>
      { aiAdvice === '' || aiImageAdvice === '' ?
        <div style={overlayStyle}>
          <BounceLoader
            color='#36d7b7'
            loading={aiAdvice === '' || aiImageAdvice === ''}
            cssOverride={override}
            size={150}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      : <></>
    }
    </div>
  );
}

export default Advice