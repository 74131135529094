import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import image1 from '../img/image1.png';
import image2 from '../img/image2.png';

const About = props => {
  return (
    <div>
      <Row>
        <Col lg="6">
          <p>DENTAL STATUSは、歯と口の健康リスクを予測します。</p>
          <div>
            <h5>1. 歯と歯ぐきが画面の中央に来るように撮影してください。</h5>
            <Image src={image1} fluid={true} style={{width: "90%", height: "auto"}} />
            <ul>
              <li>明るい場所で見本のように写真を撮影してください</li>
            </ul>
          </div>
        </Col>
        <Col lg="6">
          <div>
            <h5>2. AIで歯と口の健康リスクを予測します。</h5>
            <Image src={image2} fluid={true} style={{width: "90%", height: "auto"}} />
            <ol>
              <li>歯石の状況</li>
              <li>歯肉の形状（腫れや丸み）</li>
              <li>歯茎の色</li>
            </ol>
            などから予測が行われます。
          </div>
          <p style={{fontSize: "8pt"}}>※確定的な診断結果をお伝えするものではありません</p>
        </Col>
      </Row>
    </div>
  );
}

export default About;