import React, { useRef, useContext, useState } from 'react';
import { RecognizeContext } from '../App';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import Cropper from 'react-cropper';
import interaction01 from '../img/interaction01.png';
import interaction02 from '../img/interaction02.png';
import interaction03 from '../img/interaction03.png';

const Camera = props => {
  const { setImage } = useContext(RecognizeContext)
  const [beforeCropImage, setBeforCropImage] = useState(undefined);
  const [cropMode, setCropMode] = useState(false);
  const [cropInitialized, setCropInitialized] = useState(false);
  const inputFileRef = useRef();
  const cropperRef = useRef();
  
  const history = useHistory();

  const cropImage = () => {
    const imageEl = cropperRef.current;
    const cropper = imageEl.cropper;
    setImage(cropper.getCroppedCanvas().toDataURL());
    setCropMode(false);
    history.goBack();
  }

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    }
    reader.readAsDataURL(file);
  });

  return (
    (cropMode)
      ?
      <div style={{position: "relative"}}>
        <Cropper
          src={beforeCropImage}
          aspectRatio={4 / 3}
          ref={cropperRef}
          onInitialized={() => setCropInitialized(true)}
        />
        <Button
          onClick={cropImage}
          size="lg"
          disabled={!cropInitialized}
          style={{ position: "absolute", top: "0", left: "0" }}>
            切り取る
          </Button>
      </div>
      :
      <div>
        <Row>
          <Col lg="6">
            <div style={{marginBottom: "1.5em"}}>
              <p style={{fontWeight: "bold"}}>1. カメラボタンで写真を撮影します</p>
              <Image src={interaction01} fluid={true} style={{ width: "70%", height: "auto" }} />
              <ul style={{ marginTop: "1em" }}>
                <li>明るい環境で撮影してください</li>
                <li>なるべくスマホに近づいて、歯ぐきが写るように撮影してください</li>                
              </ul>
            </div>
          </Col>
          <Col lg="6">
            <div style={{marginBottom: "1.5em"}}>
              <p style={{fontWeight: "bold"}}>2. 歯と歯ぐきの部分が中心に来るように、調整します<br />青枠の位置を変更することもできます</p>
              <Image src={interaction02} fluid={true} style={{width: "70%", height: "auto"}} />
            </div>
            <div style={{marginBottom: "1.5em"}}>
              <p style={{fontWeight: "bold"}}>3. 青枠を調整できたら左上の「切り取る」を押します<br />「撮影した画像でチェックする」を押します</p>
              <Image src={interaction03} fluid={true} style={{width: "70%", height: "auto"}} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="d-grid gap-2" style={{margin: "1em auto"}}>
              <Button size="lg" onClick={() => {history.goBack()}}>戻る</Button>
            </div>
          </Col>
          <Col lg="6">
            <div className="d-grid gap-2" style={{margin: "1em auto 3em"}}>
              <Button id="camera-button" size="lg" onClick={() => {inputFileRef.current.click()}}>口腔画像を撮影する</Button>
              <input
                type="file"
                accept="image/*;capture=camera"
                ref={inputFileRef}
                onChange={(e) => {
                  const file = e.target.files[0];
                  fileToDataUri(file)
                    .then(dataUri => {
                      setBeforCropImage(dataUri);
                      setCropInitialized(false);
                      setCropMode(true);
                    });
                }}
                style={{ display: "none" }}
              />
            </div>
          </Col>
        </Row>
      </div>
  );
}

export default Camera;