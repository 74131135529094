import React, { useContext } from 'react';
import { RecognizeContext } from '../App';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const DentalStatusNavbar = props => {
  const { orgCode } = useContext(RecognizeContext);

  return (
    (orgCode !== "")
      ?
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"  id="navbar">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Dental Status</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/about">
                <Nav.Link>Dental Status とは</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      :
      <div></div>
  );
};

export default DentalStatusNavbar;