import React from 'react';
import Iframe from 'react-iframe';
import './Terms.css';

const Terms = props => {
  return (
    <Iframe url={process.env.PUBLIC_URL + '/resource/terms.html'} width="100%" />
  );
};

export default Terms;