import React, { useState, useContext } from 'react';
import { RecognizeContext } from '../App';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { fetch } from 'whatwg-fetch';
import { BounceLoader } from 'react-spinners';
import 'cropperjs/dist/cropper.css';
import hanarabi from '../img/hanarabi.jpg';

const override = {
  display: 'block',
  marin: '0 auto',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}

const Recognize = props => {
  const { image, setRecognizeResult, orgCode, userCode } = useContext(RecognizeContext)
  const [message, setMessage] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const history = useHistory();

  window.gotResponse = false;

  const recognizeImage = () => {
    openModal();
    window.gotResponse = false;
    fetch(process.env.REACT_APP_API_ROOT + "/dental_status", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        image: image.replace(/^.*,/, ''),
        org_code: orgCode,
        user_code: userCode
      })
    }).then(response => response.json()).then((responseJson) => {
      console.log(responseJson);
      if (responseJson.top_class === "no dental") {
        setMessage("再度撮り直してください");
        closeModal();
      } else {
        setRecognizeResult(responseJson);
        window.gotResponse = true;
        closeModal();
        history.push("/result");
      }
    }, (error) => {
      console.log(error);
      setMessage("エラーが発生しました");
      closeModal();
    });
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <div style={{ textAlign: "center", padding: "1em auto" }}>
      <Row>
        <Col lg="6">
          <div className="d-grid gap-2">
            <Button id="camera-button" size="lg" onClick={() => {history.push("/camera")}}>口腔画像を撮影する</Button>
          </div>
          <div>
            <Image src={image} fluid={true} style={{margin: "1em auto", backgroundColor: "#ccc", width: "382px", height: "286px"}} />
          </div>
          <div className="d-grid gap-2">
            <Button id="recognize-button" size="lg" disabled={image === undefined} onClick={recognizeImage}>撮影した画像でチェックする</Button>
          </div>
          <div style={{textAlign: "center", margin: "1em auto"}}>
            <span style={{fontSize: "14pt", color: "red", fontWeight: "bold"}}>{ message }</span>
          </div>
        </Col>
        <Col lg="6">
          <div>
            <div style={{textAlign: "center", fontSize: "14pt", fontWeight: "bold"}}>＜このアプリの使い方＞</div>
            <div>
              <p>①「口腔画像を撮影する」をタップして、画像を撮影します</p>
              <div style={{textAlign: "center", marginTop: "1em"}}>
                <Image src={hanarabi} fluid={true} style={{width: "382px", height: "286px", marginBottom: "1em"}} /><br />
                <p>※白色灯下で見本のように写真を撮影・加工してください</p>
              </div>
            </div>
            <div>
              <p>②「撮影した画像をチェックする」をタップすると、結果が表示されます</p>
            </div>
          </div>
        </Col>
      </Row>
      { modalIsOpen ?
        <div style={overlayStyle}>
          <BounceLoader
            color='#36d7b7'
            loading={modalIsOpen}
            cssOverride={override}
            size={150}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      : <></>
      }
    </div>
  );
}

export default Recognize