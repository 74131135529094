import React, { useRef, useContext, useState, useEffect } from 'react';
import { RecognizeContext } from '../App';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Webcam from 'react-webcam';
import { fetch } from 'whatwg-fetch';

const RecognizeCamera = props => {
  const webcamRef = useRef(null);
  const {image, setImage, setRecognizeResult, recognizeResult, orgCode, userCode} = useContext(RecognizeContext)
  const [currentDeviceId, setCurrentDeviceId] = useState([]);
  const [devices, setDevices] = useState([]);
  const [captureImage, setCaptureImage] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isWaitResult, setIsWaitResult] = useState(false);
  const [captureResult, setCaptureResult] = useState(null);
  const [isDental, setIsDental] = useState(false);
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");

  const history = useHistory()

  window.gotResponse = false;

  useEffect(() => {
    const handleDevices = (mediaDivices) => {
      const tmpDevices = mediaDivices.filter(({kind}) => kind === 'videoinput');
      console.log(tmpDevices);
      setDevices(tmpDevices);
      if (tmpDevices.length > 0) {
        setCurrentDeviceId(tmpDevices[0].deviceId);
      }
    };
  
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, []);

  useEffect(() => {
    let intervalId;

    if (isCapturing) {
      intervalId = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (!isWaitResult && imageSrc !== null) {
          setIsWaitResult(true);
          fetch(process.env.REACT_APP_API_ROOT + '/dental_status', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              image: imageSrc.replace(/^.*,/, ''),
              org_code: orgCode,
              user_code: userCode
            })
          }).then(response => response.json()).then((responseJson) => {
            console.log(responseJson);
            setIsWaitResult(false);
            setCaptureImage(imageSrc);
            if (responseJson.top_class === "no dental") {
              setMessage1("NO DENTAL");
              setIsDental(false);
            } else if (responseJson.top_class === "error") {
              console.log('top_class: error (skip!)');
            } else {
              setCaptureResult(responseJson);
              setMessage1(`${getLabel(responseJson.top_class)} : ${Math.round(responseJson.confidence * 100)}%`);
              setIsDental(true);
            }
          }, (error) => {
            console.log(error);
          });  
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isCapturing, orgCode, userCode, setImage, setRecognizeResult, isWaitResult]);

  const changeCamera = (event) => {
    const selectedDeviceId = event.target.value;
    //alert(selectedDeviceId);
    console.log(`changeCamera: ${selectedDeviceId}`)
    setCurrentDeviceId(selectedDeviceId);
  }

  const toggleCapturing = () => {
    setIsCapturing(!isCapturing);
  };

  const shootImage = () => {
    setImage(captureImage);
    setMessage2(`${getLabel(captureResult.top_class)} : ${Math.round(captureResult.confidence * 100)}%`);
    setRecognizeResult(captureResult);
  };

  const getLabel = (topClass) => {
    switch (topClass) {
      case 'low':
        topClass = '健康';
        break;
      case 'medium':
        topClass = '要注意';
        break;
      case 'heavy':
        topClass = '受診勧奨';
        break;
      default:
        break;
    }
    return topClass;
  } 

  const goResult = () => {
    window.gotResponse = true;
    history.push("/result");
  }

  return (
    <div style={{ textAlign: "center", padding: "1em auto" }}>
      <div style={{ marginBottom: "2em" }}>
        <div style={{fontSize: "24pt", color: "white", fontWeight: "bold"}}>
          { isCapturing
            ? recognizeResult
              ? '健康状態のリスクを確認して、「決定」をタップしてください。'
              : '健康状態のリスクを確認して、「撮影」をタップしてください。'
            : '健康状態のリスクを予測します。「開始」をタップしてください。'
          }
        </div>
        <div style={{fontSize: "16pt", color: "white", fontWeight: "bold"}}>
          { isCapturing
            ? recognizeResult
              ? '撮り直しをしたい場合は、再度「撮影」ボタンをタップしてください。'
              : '「NO DENTAL」が表示される場合は、近づいて、歯と歯ぐきをハッキリ映してください。'
            : ''
          }
        </div>
      </div>      
      <Row>
        <Col lg="4">
          {
            captureImage
            ? <img src={captureImage} alt="Latest capture" width={"98%"} />
            : <div />
          }
          <div style={{textAlign: "center", margin: "1em auto"}}>
            <span style={{fontSize: "14pt", color: "white", fontWeight: "bold"}}>{ message1 }</span>
          </div>
          {
            image
            ? <img src={image} alt="Latest capture" width={"98%"} />
            : <div />
          }
          <div style={{textAlign: "center", margin: "1em auto"}}>
            <span style={{fontSize: "14pt", color: "white", fontWeight: "bold"}}>{ message2 }</span>
          </div>
          <div className="d-grid gap-2">
            {
              image
              ? <Button id="result-button" size="lg" onClick={goResult}>決定</Button>
              : <div /> 
            }
          </div>
        </Col>
        <Col lg="8">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            //forceScreenshotSourceSize={true}
            videoConstraints={{
              width: 1920,
              height: 1080,
              deviceId: currentDeviceId
            }}
            width={"98%"}
          />
          <Row style={{ marginTop: "1em" }}>
            <Col lg="6">
              <div className="d-grid gap-2">
                <Button id="camera-toggle-button" size="lg" onClick={toggleCapturing}>
                  { isCapturing ? '一時停止' : '開始' }
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div className="d-grid gap-2">
                <Button id="shutter-button" size="lg" onClick={shootImage} disabled={!isDental}>
                  撮影
                </Button>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }}>
            <Form.Select onChange={changeCamera}>
              {devices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>{device.label} ({device.deviceId})</option>
              ))}
            </Form.Select>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RecognizeCamera;
